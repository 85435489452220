import { useEffect } from "react";
import { useWalletClient, useAccount, useDisconnect } from "wagmi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { SiweMessage } from "siwe";

import { SiweSession } from "../web3/siwe/siwe";
import { useGetOIDCParams } from "../hooks/useGetOIDCParams";

export function usePromptSign(requestNonce: number) {
  const { address, isConnected } = useAccount();
  const { data: walletClient, isError, isLoading } = useWalletClient();
  const OIDCParams = useGetOIDCParams();
  const { domain, nonce, redirect, state, client_id, paramError, oidc_nonce } =
    OIDCParams;
  const { disconnectAsync } = useDisconnect();

  useEffect(() => {
    if (paramError) {
      //This is not an error we can recover from and should not occur in normal usage
      console.log(
        "Missing parameters please ensure the following are passed correctly:\ndomain, nonce, redirect_uri, state, oidc_nonce, client_id"
      );
      window.location.replace(process.env.REACT_APP_ERROR_FALLBACK || "");
      return;
    }

    if (
      isConnected &&
      address &&
      walletClient &&
      !isLoading &&
      !isError &&
      requestNonce !== 0
    ) {
      (async () => {
        try {
          let signDomain = window.location.host || "";
          //@todo: Make this configurable
          const fortyEightHours = 48 * 60 * 60 * 1000;
          const expirationTime = new Date(
            new Date().getTime() + fortyEightHours
          );

          //this statement is derived from spruce-oidc
          const statement = `You are signing-in to ${signDomain}.`;
          const chainId = walletClient.chain.id;

          //generate the swie spec message for signing with the following params
          const swieRawMessage = new SiweMessage({
            domain: signDomain,
            address: address,
            chainId: chainId,
            expirationTime: expirationTime.toISOString(),
            uri: window.location.origin,
            version: "1",
            statement: statement,
            nonce: nonce || "",
            resources: [redirect || ""],
          }).prepareMessage();

          toast.info("Please sign the message on your wallet app to continue", {
            position: "bottom-center",
          });
          //prompt the message to be signed
          // @ts-ignore
          const signature = await walletClient.signMessage({
            message: swieRawMessage,
          });

          if (!signature) {
            alert("No signature returned");
            throw new Error("No signature returned");
          }

          toast.success("You are being redirected back to myna shortly...", {
            position: "bottom-center",
          });

          const message = new SiweMessage(swieRawMessage);
          const session: SiweSession = {
            message,
            raw: swieRawMessage,
            signature,
          };
          Cookies.set("siwe", JSON.stringify(session), {
            expires: expirationTime,
          });

          let oidc_nonce_param = "";
          if (oidc_nonce != null && oidc_nonce !== "") {
            oidc_nonce_param = `&oidc_nonce=${oidc_nonce}`;
          }

          //Now we have the session we can redirect back
          setTimeout(() => {
            window.location.replace(
              `/sign_in?redirect_uri=${encodeURI(redirect)}&state=${encodeURI(
                state
              )}&client_id=${encodeURI(client_id)}${encodeURI(
                oidc_nonce_param
              )}`
            );
          }, 500);
        } catch (e) {
          console.log(e);
          Cookies.remove("siwe");
          await disconnectAsync();
          window.localStorage.clear();
          window.location.reload();
        }
      })();
    }
  }, [
    isConnected,
    address,
    requestNonce,
    OIDCParams,
    walletClient,
    isLoading,
    isError,
  ]);
}
