import type { Chain } from "wagmi";

export const avalanche: Chain = {
  id: 43114,
  name: "Avalanche Network",
  network: "Avalanche Network",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: {
    public: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
    default: { http: ["https://api.avax.network/ext/bc/C/rpc"] },
  },
  blockExplorers: {
    default: {
      name: "Avalanche Explorer",
      url: "https://snowtrace.io/",
    },
  },
};
