import { IconProps, Icons, ToastContainer } from "react-toastify";
import { ToastErrorIcon, ToastSuccessSvg } from "../constants/Svgs";

import "react-toastify/dist/ReactToastify.css";

enum ToastType {
  Default = "default",
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

const bodyStyles = {
  alignItems: "flex-start",
  display: "flex",
};

const toastStyles = () => {
  return {
    backgroundColor: "#F0EFE5",
    border: `1px solid #B8B5A7`,
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.05)",
    fontFamily: "Inter",
    margin: "auto",
    maxWidth: "300px",
    marginBottom: "8px",
  };
};

export default function Toast() {
  return (
    <ToastContainer
      theme="light"
      hideProgressBar
      icon={({ theme, type }: IconProps) => {
        switch (type) {
          case ToastType.Error:
            return (
              <div className={"icon-container"}>
                <ToastErrorIcon />
              </div>
            );
          case ToastType.Success:
            return (
              <div className={"icon-container"}>
                <ToastSuccessSvg />
              </div>
            );
          case ToastType.Warning:
            return (
              <div className={"icon-container"}>
                <Icons.warning theme={theme} type={type} />
              </div>
            );
          case ToastType.Info:
            return (
              <div className={"icon-container"}>
                <Icons.info theme={theme} type={type} />
              </div>
            );
          default:
            return (
              <div className={"icon-container"}>
                <Icons.spinner />
              </div>
            );
        }
      }}
      position="bottom-center"
      className={"react-toast-container"}
      bodyStyle={bodyStyles}
      toastStyle={toastStyles()}
    />
  );
}
