import { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";

import { usePromptSign } from "./hooks/usePromptSign";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useAccount } from "wagmi";
import { MynaLogo } from "./constants/Svgs";
import Toast from "./components/Toast";

function App() {
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const [requestNonce, updateRequestNonce] = useState<number>(0);

  const handleSignIn = () => {
    if (!isConnected) open();
    updateRequestNonce(requestNonce + 1);
  };

  useEffect(() => {
    handleSignIn();
  }, []);

  usePromptSign(requestNonce);

  return (
    <div className="App">
      <div className="header">
        <MynaLogo />
      </div>
      <div className="footer">
        <a href="#">Terms of use</a>
        <a href="#">Privacy policy</a>
      </div>
      <Toast />
    </div>
  );
}

export default App;
