//This hook is used to get the OIDC parameters from the URL
export function useGetOIDCParams() {
  const params = new URLSearchParams(window.location.search);

  const domain = params.get("domain") || "";
  const nonce = params.get("nonce") || "";
  const redirect = params.get("redirect_uri") || "";
  const state = params.get("state") || "";
  const client_id = params.get("client_id") || "";
  const oidc_nonce = params.get("oidc_nonce") || "";

  let paramError = false;
  if (!domain || !nonce || !redirect || !state || !client_id) {
    paramError = true;
  }

  return {
    domain,
    nonce,
    redirect,
    state,
    client_id,
    paramError,
    oidc_nonce,
  };
}
