export const MynaLogo = () => (
  <svg
    width="129"
    height="34"
    viewBox="0 0 129 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.6217 11.2607H57.5213L60.9451 18.6914L64.3528 11.2607H70.2353V22.7876H66.5426V14.5009L62.7817 22.7876H59.0915L55.3145 14.5009V22.7876H51.6226V11.2607H51.6217ZM77.1868 18.9345L70.934 11.2607H75.6277L79.0677 15.7717L82.5238 11.2607H87.2174L80.9647 18.9345V22.7876H77.1877V18.9345H77.1868ZM87.9153 11.2607H93.5945L99.4932 19.4989V11.2607H103.185V22.7876H97.5068L91.6081 14.5332V22.7876H87.9162V11.2607H87.9153ZM115.177 11.2607H110.355L104.44 22.7876H108.459L109.488 20.6524H116.03L117.043 22.7876H121.078L115.177 11.2607ZM110.773 17.9392L112.75 13.8133L114.727 17.9392H110.773Z"
      fill="#2B2015"
    />
    <path
      d="M44.1187 26.52L38.2455 11.3075C37.3613 9.01848 35.1162 7.47998 32.6583 7.47998C30.2004 7.47998 27.9553 9.01848 27.0711 11.3075L25.8838 14.3828C25.8838 14.3828 24.9511 11.9714 24.6311 11.1571C23.7076 8.95218 21.5119 7.47998 19.1094 7.47998C16.6515 7.47998 14.4064 9.01848 13.5221 11.3075L7.64893 26.52H11.5213C14.4872 26.52 17.1476 24.6976 18.2149 21.9342L19.1102 19.6163C19.1102 19.6163 20.3502 22.8157 20.363 22.8429C21.2864 25.0478 23.4821 26.52 25.8847 26.52C28.3425 26.52 30.5877 24.9815 31.4719 22.6924L32.6591 19.6163L33.5536 21.9342C34.6208 24.6976 37.2804 26.52 40.2472 26.52H44.1187ZM22.2881 22.5607C22.1851 22.5607 21.8387 22.5309 21.6923 22.1518L19.9111 17.5389L21.4132 13.6493L22.1085 11.8481C22.2549 11.469 22.6021 11.4393 22.7042 11.4393C22.8072 11.4393 23.1545 11.469 23.3008 11.8481L25.0821 16.4611L22.8847 22.1527C22.7383 22.5309 22.3911 22.5607 22.2881 22.5607ZM16.8183 21.397C15.98 23.5679 13.8515 25.0265 11.5213 25.0265H9.82935L14.9187 11.8456C15.5817 10.1286 17.2659 8.97428 19.1094 8.97428C20.1953 8.97428 21.2234 9.37718 22.0225 10.0563C21.4302 10.2535 20.9468 10.7023 20.7119 11.3109L16.8183 21.397ZM30.0745 22.1544C29.4115 23.8714 27.7272 25.0257 25.8838 25.0257C24.7979 25.0257 23.7698 24.6228 22.9706 23.9436C23.563 23.7464 24.0464 23.2976 24.2813 22.689L28.4685 11.8447C29.1315 10.1277 30.8157 8.97343 32.6591 8.97343C33.7451 8.97343 34.7732 9.37633 35.5723 10.0555C34.98 10.2527 34.4966 10.7015 34.2617 11.3101L30.0745 22.1544ZM34.9494 21.397L33.46 17.5397L35.6574 11.8481C35.8038 11.469 36.1511 11.4393 36.254 11.4393C36.357 11.4393 36.7042 11.469 36.8506 11.8481L41.9383 25.0265H40.2464C37.9162 25.0257 35.7876 23.5671 34.9494 21.397Z"
      fill="#2B2015"
    />
  </svg>
);

export const ToastErrorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#BF3E2B" />
    <rect x="7" y="3" width="2" height="7" rx="1" fill="#F0EFE5" />
    <rect x="7" y="11" width="2" height="2" rx="1" fill="#F0EFE5" />
  </svg>
);

export const ToastSuccessSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#459F6C" />
    <path
      d="M4.29261 7.75645C4.68275 7.36672 5.3153 7.36672 5.70544 7.75645L7.74435 9.79281C8.13449 10.1825 8.13449 10.8144 7.74435 11.2041C7.3542 11.5939 6.72166 11.5939 6.33151 11.2041L4.29261 9.16778C3.90246 8.77805 3.90246 8.14618 4.29261 7.75645Z"
      fill="#F0EFE5"
    />
    <path
      d="M10.5875 5.5423C10.9776 5.15257 11.6101 5.15257 12.0003 5.5423C12.3904 5.93203 12.3904 6.5639 12.0003 6.95363L7.74543 11.2025C7.35529 11.5923 6.72274 11.5923 6.3326 11.2025C5.94245 10.8128 5.94245 10.1809 6.3326 9.7912L10.5875 5.5423Z"
      fill="#F0EFE5"
    />
  </svg>
);
